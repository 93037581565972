import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from 'layouts/dashboard'
import Invoice from 'components/dashboard/invoices'
import AdminLogin from 'components/login/admin'

function Home() {
  return (
    <Dashboard>
      <Invoice/>
    </Dashboard>
  );
}

export default Home;
