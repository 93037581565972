const PAGE_LIMIT = 8;
const BIG_LIMIT = 1000;

export const configureDeliveryDeliverycompany = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    deliverycompany_fetch_list: state["delivery"]?.deliverycompany_list,
    deliverycompany_fetch_item: state["delivery"]?.deliverycompany_item,
    deliverycompany_create_item: state["delivery"]?.deliverycompany_item,
    deliverycompany_update_item: state["delivery"]?.deliverycompany_item,
    deliverycompany_delete_item: state["delivery"]?.deleted_deliverycompany,
  },
  action: {
    fetchDeliverycompanyList: () => {
      const deliverycompany_fetch_list_request = {
        dispatch,
        request: ["delivery", "deliverycompany", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(deliverycompany_fetch_list_request);
    },
    fetchDeliverycompanyItem: (id) => {
      const deliverycompany_fetch_item_request = {
        dispatch,
        request: ["delivery", "deliverycompany", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliverycompany_fetch_item_request);
    },
    createDeliverycompanyItem: (payload) => {
      const deliverycompany_create_item_request = {
        dispatch,
        request: ["delivery", "deliverycompany", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(deliverycompany_create_item_request);
    },
    updateDeliverycompanyItem: (id, payload) => {
      const deliverycompany_update_item_request = {
        dispatch,
        request: ["delivery", "deliverycompany", "update"],
        request_info: { id, ...payload },
        auth: false,
      };
      utils.action(deliverycompany_update_item_request);
    },
    deleteDeliverycompanyItem: (id) => {
      const deliverycompany_delete_item_request = {
        dispatch,
        request: ["delivery", "deliverycompany", "delete"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliverycompany_delete_item_request);
    },
  },
});

export const configureDeliveryDeliveryperson = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    deliveryperson_fetch_list: state["delivery"]?.deliveryperson_list,
    deliveryperson_fetch_item: state["delivery"]?.deliveryperson_item,
    deliveryperson_create_item: state["delivery"]?.deliveryperson_item,
    deliveryperson_update_item: state["delivery"]?.deliveryperson_item,
    deliveryperson_delete_item: state["delivery"]?.deleted_deliveryperson,
  },
  action: {
    fetchDeliverypersonList: () => {
      const deliveryperson_fetch_list_request = {
        dispatch,
        request: ["delivery", "deliveryperson", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(deliveryperson_fetch_list_request);
    },
    fetchDeliverypersonItem: (id) => {
      const deliveryperson_fetch_item_request = {
        dispatch,
        request: ["delivery", "deliveryperson", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliveryperson_fetch_item_request);
    },
    createDeliverypersonItem: (payload) => {
      const deliveryperson_create_item_request = {
        dispatch,
        request: ["delivery", "deliveryperson", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(deliveryperson_create_item_request);
    },
    updateDeliverypersonItem: (id, payload) => {
      const deliveryperson_update_item_request = {
        dispatch,
        request: ["delivery", "deliveryperson", "update"],
        request_info: { id, ...payload },
        auth: false,
      };
      utils.action(deliveryperson_update_item_request);
    },
    deleteDeliverypersonItem: (id) => {
      const deliveryperson_delete_item_request = {
        dispatch,
        request: ["delivery", "deliveryperson", "delete"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliveryperson_delete_item_request);
    },
  },
});

export const configureDeliveryDeliveryorder = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    deliveryorder_fetch_list: state["delivery"]?.deliveryorder_list,
    deliveryorder_fetch_item: state["delivery"]?.deliveryorder_item,
    deliveryorder_create_item: state["delivery"]?.deliveryorder_item,
    deliveryorder_update_item: state["delivery"]?.deliveryorder_item,
    deliveryorder_delete_item: state["delivery"]?.deleted_deliveryorder,
  },
  action: {
    fetchDeliveryorderList: () => {
      const deliveryorder_fetch_list_request = {
        dispatch,
        request: ["delivery", "deliveryorder", "list"],
        request_info: {},
        auth: true,
      };
      utils.action(deliveryorder_fetch_list_request);
    },
    fetchDeliveryorderItem: (id) => {
      const deliveryorder_fetch_item_request = {
        dispatch,
        request: ["delivery", "deliveryorder", "read"],
        request_info: { lookup_field: "order_id", order_id: id },
        auth: true,
      };
      utils.action(deliveryorder_fetch_item_request);
    },
    createDeliveryorderItem: async (payload) => {
      const deliveryorder_create_item_request = {
        dispatch,
        request: ["delivery", "deliveryorder", "create"],
        request_info: payload,
        auth: false,
      };
      await utils.action(deliveryorder_create_item_request);
    },
    updateDeliveryorderItem: (id, payload) => {
      const deliveryorder_update_item_request = {
        dispatch,
        request: ["delivery", "deliveryorder", "update"],
        request_info: { id, ...payload },
        auth: true,
      };
      utils.action(deliveryorder_update_item_request);
    },
    deleteDeliveryorderItem: (id) => {
      const deliveryorder_delete_item_request = {
        dispatch,
        request: ["delivery", "deliveryorder", "delete"],
        request_info: { id },
        auth: true,
      };
      utils.action(deliveryorder_delete_item_request);
    },
  },
});

export const configureDeliveryDeliverytracking = (utils, dispatch, state) => ({
  initialize: () => {},
  data: {
    deliverytracking_fetch_list: state["delivery"]?.deliverytracking_list,
    deliverytracking_fetch_item: state["delivery"]?.deliverytracking_item,
    deliverytracking_create_item: state["delivery"]?.deliverytracking_item,
    deliverytracking_update_item: state["delivery"]?.deliverytracking_item,
    deliverytracking_delete_item: state["delivery"]?.deleted_deliverytracking,
  },
  action: {
    fetchDeliverytrackingList: () => {
      const deliverytracking_fetch_list_request = {
        dispatch,
        request: ["delivery", "deliverytracking", "list"],
        request_info: {},
        auth: false,
      };
      utils.action(deliverytracking_fetch_list_request);
    },
    fetchDeliverytrackingItem: (id) => {
      const deliverytracking_fetch_item_request = {
        dispatch,
        request: ["delivery", "deliverytracking", "read"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliverytracking_fetch_item_request);
    },
    createDeliverytrackingItem: (payload) => {
      const deliverytracking_create_item_request = {
        dispatch,
        request: ["delivery", "deliverytracking", "create"],
        request_info: payload,
        auth: false,
      };
      utils.action(deliverytracking_create_item_request);
    },
    updateDeliverytrackingItem: (id, payload) => {
      const deliverytracking_update_item_request = {
        dispatch,
        request: ["delivery", "deliverytracking", "update"],
        request_info: { id, ...payload },
        auth: false,
      };
      utils.action(deliverytracking_update_item_request);
    },
    deleteDeliverytrackingItem: (id) => {
      const deliverytracking_delete_item_request = {
        dispatch,
        request: ["delivery", "deliverytracking", "delete"],
        request_info: { id },
        auth: false,
      };
      utils.action(deliverytracking_delete_item_request);
    },
  },
});
