import axios from '../utils/axios';

import {fetchAccountUserprofileListStart, fetchAccountUserprofileListSuccess, fetchAccountUserprofileListFailure} from '../features/account'
import {fetchAccountUserprofileItemStart, fetchAccountUserprofileItemSuccess, fetchAccountUserprofileItemFailure} from '../features/account'
import {createAccountUserprofileItemStart, createAccountUserprofileItemSuccess, createAccountUserprofileItemFailure} from '../features/account'
import {updateAccountUserprofileItemStart, updateAccountUserprofileItemSuccess, updateAccountUserprofileItemFailure} from '../features/account'
import {deleteAccountUserprofileItemStart, deleteAccountUserprofileItemSuccess, deleteAccountUserprofileItemFailure} from '../features/account'


// USERPROFILE ACTIONS
export const fetchAccountUserprofileList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountUserprofileListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileListFailure({key, error: error.message}));
    }
}
export const fetchAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const createAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/userprofile/`, info.payload, { headers: info.headers });
        dispatch(createAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const updateAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const deleteAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountUserprofileItemFailure({key, error: error.message}));
    }
}




