import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import _, { result } from "lodash";
import {
  Dropdown,
  Menu,
  Grid,
  Segment,
  Header,
  Container,
  Search,
  Divider,
  Icon,
  Sidebar,
  Checkbox,
  Button,
  Popup,
} from "semantic-ui-react";

const SideBar = ({ state, setState }) => {
  const { activeItem } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setResults] = useState({
    tasks: [],
    notes: [],
    surveys: [],
    draft: { tasks: [], notes: [], surveys: [] },
  });
  const [values, setValue] = useState("");

  const handleSearchChange = async (event, { value }) => {
    setIsLoading(true);
    setValue(value);
    try {
    } catch (error) {
      console.error("Error occurred while fetching search results:", error);
      setResults([]);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const debouncedHandleSearchChange = _.debounce(handleSearchChange, 1500, {
    leading: true,
  });

  const isSearchResultEmpty =
    searchResult.tasks.length === 0 &&
    searchResult.notes.length === 0 &&
    searchResult.surveys.length === 0 &&
    searchResult.draft?.tasks.length === 0 &&
    searchResult.draft?.notes.length === 0 &&
    searchResult.draft?.surveys.length === 0;

  const handleItemClick = (e, { name }) => setState({ activeItem: name });

  const menuItems = [
    [
      {
        name: "products",
        to: "/admin/dashboard/products",
        active: activeItem === "products",
        content: "Products",
      },
      // {
      //   name: "suppliers",
      //   to: "/admin/dashboard/suppliers",
      //   active: activeItem === "suppliers",
      //   content: "Suppliers",
      // },
      {
        name: "categories",
        to: "/admin/dashboard/categories",
        active: activeItem === "categories",
        content: "Categories",
      },
    ],
    [
      {
        name: "orders",
        to: "/admin/dashboard/orders",
        active: activeItem === "orders",
        content: "Orders",
      },
      {
        name: "payments",
        to: "/admin/dashboard/payments",
        active: activeItem === "payments",
        content: "Payments",
      },
      // {
      //   name: "invoices",
      //   to: "/admin/dashboard/invoices",
      //   active: activeItem === "invoices",
      //   content: "Invoices",
      // },
      // {
      //   name: "bills",
      //   to: "/admin/dashboard/bills",
      //   active: activeItem === "bills",
      //   content: "Bills",
      // },
    ],
    [
      {
        name: "notifications",
        to: "/admin/dashboard/notifications",
        active: activeItem === "notifications",
        content: "Notifications",
      },
    ],
    [
      // {
      //   name: "countries-and-regions",
      //   to: "/admin/dashboard/countries-and-regions",
      //   active: activeItem === "countries-and-regions",
      //   content: "Countries & Regions",
      // },
      {
        name: "settings",
        to: "/admin/dashboard/settings",
        active: activeItem === "settings",
        content: "Settings",
      },
    ],
  ];

  return (
    <Menu vertical style={{ backgroundColor: "#2e3642" }} fluid inverted>
      <Menu.Menu>
        <Menu.Item>
          <h3 style={{ textAlign: "center", marginTop: 10, minWidth: 200 }}>
            Dashboard
          </h3>
        </Menu.Item>
      </Menu.Menu>
      {menuItems.map((subMenu, index) => (
        <Menu.Item key={index}>
          <Menu.Menu>
            {subMenu.map((item) => (
              <Menu.Item
                key={item.name}
                name={item.name}
                as={Link}
                to={item.to}
                active={item.active}
                onClick={handleItemClick}
                content={item.content}
              />
            ))}
          </Menu.Menu>
        </Menu.Item>
      ))}
      {/*<Dropdown item text="More">
        <Dropdown.Menu>
          <Dropdown.Item
            text="Setup Organization"
            as={Link}
            to="/add-organization"
          />
          <Dropdown.Item
            text="Logout"
            onClick={() => {
              window.location.reload(false);
            }}
          />

          <Dropdown.Item text="Profile" as={Link} to="/dashboard/profile" />
        </Dropdown.Menu>
      </Dropdown>*/}
    </Menu>
  );
};

const DashboardLayout = ({ activeItem, style }) => {
  const [state, setState] = useState({ activeItem: activeItem });
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const [sidebarWidth, setSidebarWidth] = useState();
  const sidebarRef = useRef(null);
  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  useEffect(() => {
    if (sidebarRef.current && sidebarExpanded) {
      setSidebarWidth(sidebarRef.current.scrollWidth + 10);
    } else {
      setSidebarWidth(0);
    }
  }, [sidebarExpanded]);

  const menuItems = [
    {
      name: "Products",
      icon: "shopping cart",
      link: "/admin/dashboard/products",
    },
    // { name: 'Suppliers', icon: 'users', link: '/admin/dashboard/suppliers' },
    {
      name: "Categories",
      icon: "list alternate outline",
      link: "/admin/dashboard/categories",
    },
    { name: "Orders", icon: "clipboard list", link: "/admin/dashboard/orders" },
    {
      name: "Payments",
      icon: "money bill alternate outline",
      link: "/admin/dashboard/payments",
    },
    // { name: 'Invoices', icon: 'file alternate outline', link: '/admin/dashboard/invoices' },
    {
      name: "Notifications",
      icon: "bell outline",
      link: "/admin/dashboard/notifications",
    },
    // { name: 'Bills', icon: 'file invoice outline', link: '/admin/dashboard/bills' },
    // { name: 'Countries & Regions', icon: 'globe', link: '/admin/dashboard/countries-and-regions' },
    // { name: 'Settings', icon: 'setting', link: '/admin/dashboard/settings' },
  ];

  return (
    <div style={{ display: "flex", minHeight: "150vh" }}>
      <div style={{ backgroundColor: "#222831" }}>
        <br />
        <div>
          <Button
            icon
            style={{ backgroundColor: "rgba(0,0,0,0)" }}
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
          >
            <Icon name="bars" inverted />
          </Button>
        </div>
        <br />
        {menuItems.map((menuItem, index) => (
          <div key={index}>
            <Popup
              content={menuItem.name}
              trigger={
                <Button
                  icon
                  style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  as={Link}
                  to={menuItem.link}
                >
                  <Icon name={menuItem.icon} inverted />
                </Button>
              }
            />
          </div>
        ))}
      </div>
      <div
        ref={sidebarRef}
        className={`dashboard-sidebar ${
          sidebarExpanded ? "expanded" : "shrunk"
        }`}
        style={{ maxWidth: sidebarWidth, backgroundColor: "#2e3642" }}
      >
        <SideBar {...{ state, setState }} />
      </div>
    </div>
  );
};
export default DashboardLayout;
