import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Button,
  Modal,
  Form,
  Input,
  Table,
  Icon,
  Grid,
  Pagination,
  Image,
  Confirm,
  Loader,
} from "semantic-ui-react";

import { configureProductCategory, PAGE_LIMIT } from "services/access/product";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Compressor from "compressorjs";

export const compressImage = (image) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: 0.5,
      maxWidth: 1280,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

const CategoriesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState();
  const [triggerAction, setTriggerAction] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const itemsPerpage = PAGE_LIMIT;
  const { initialize, data, action } = configureProductCategory(
    utils,
    dispatch,
    state
  );

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    fetchMore(currentPage);
  }, [currentPage]);

  const [error, setError] = useState(
    data?.category[`category_page_${currentPage}_error`]
  );

  useEffect(() => {
    setLoading(data?.category[`category_page_${currentPage}_loading`]);
  }, [currentPage, data?.category]);

  const fetchMore = (page) => {
    action.getPages(page);
  };

  useEffect(() => {
    const pageCount =
      data?.category[`category_page_${currentPage}`]?.count || 0;
    setTotalPages(Math.ceil(pageCount / itemsPerpage));
    setCategories(data?.category[`category_page_${currentPage}`]?.results);
  }, [data?.category, currentPage]);

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({
    title: "",
    description: "",
    image: undefined,
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleAddCategory = async () => {
    try {
      let compressedImage;
      const image = newCategory?.image;

      // Check if the image exists and is of the correct type
      if (image && (image instanceof File || image instanceof Blob)) {
        if (typeof compressImage === "function") {
          compressedImage = await compressImage(image);
        } else {
          // If compressImage function is not available, use the original image
          compressedImage = image;
        }
      }

      await action.createCategoryItem({
        title: newCategory?.title,
        description: newCategory.description,
        image: compressedImage,
      });

      // Set loading state and other UI states
      setIsLoading(true);
      setToastMessage("Category added successfully");
      setTriggerAction(true);
      setNewCategory({
        title: "",
        description: "",
        image: undefined,
      });
      setAddModalOpen(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteCategory = async () => {
    await action.deleteCategoryItem(selectedCategory?.id);
    setToastMessage("Category removed sucessfully");
    setTriggerAction(true);
    setDeleteModalOpen(false);
    setSelectedCategory(null);
  };

  const confirmDelete = (category) => {
    setSelectedCategory(category);
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setSelectedCategory(null);
  };

  const handleEditCategory = async () => {
    try {
      let compressedImage;
      const image = selectedCategory?.image;

      // Check if the image exists and is of the correct type
      if (image && (image instanceof File || image instanceof Blob)) {
        if (typeof compressImage === "function") {
          compressedImage = await compressImage(image);
        } else {
          // If compressImage function is not available, use the original image
          compressedImage = image;
        }
      }

      const updatedCategory = {
        ...selectedCategory,
        // Only update the image if it's available and has been processed
        ...(compressedImage && { image: compressedImage }),
      };

      await action.updateCategoryItem(selectedCategory?.id, updatedCategory);

      // Set toast message and trigger necessary actions
      setToastMessage("Category edited successfully");
      setTriggerAction(true);
      setEditModalOpen(false);
      setSelectedCategory(null);
    } catch (error) {
      // Handle any errors that occur during compression or category item update
      console.error("Error:", error);
      // Optionally set error states or show error messages to the user
    }
  };

  const confirmEdit = (category) => {
    setSelectedCategory(category);
    setEditModalOpen(true);
  };

  useEffect(() => {
    if (triggerAction) {
      if (!loading) {
        if (error) {
          toast.error("Error occured");
          setIsLoading(false);
        } else {
          fetchMore(currentPage);
          toast.success(toastMessage);
          setTriggerAction(false);
          setIsLoading(false);
        }
      }
    }
  }, [loading, triggerAction, error]);

  return (
    <Container>
      <Header as="h2">
        Categories
        <Button
          color="blue"
          style={{ marginLeft: 10 }}
          onClick={() => setAddModalOpen(true)}
          floated="right"
        >
          Add Category
        </Button>
      </Header>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Image</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading ? (
            <Loader active inline />
          ) : categories?.length > 0 ? (
            categories?.map((category) => (
              <Table.Row key={category.id}>
                <Table.Cell>{category.id}</Table.Cell>
                <Table.Cell>{category.title}</Table.Cell>
                <Table.Cell>{category.description}</Table.Cell>
                <Table.Cell>
                  <Image src={category?.image} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    basic
                    icon
                    color="blue"
                    onClick={() => confirmEdit(category)}
                  >
                    <Icon name="edit" />
                  </Button>
                  <Button
                    basic
                    icon
                    color="red"
                    onClick={() => confirmDelete(category)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={5}>
                {" "}
                <h3> No Categories Found </h3>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Grid centered>
        <Pagination
          pointing
          secondary
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePaginationChange}
        />
      </Grid>
      {/* Modal to add new category */}
      <Modal
        size="tiny"
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        closeIcon
      >
        <Modal.Header>Add Category</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input
                value={newCategory.title}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, title: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Input
                placeholder="Product description"
                value={newCategory.description}
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    description: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Image</label>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    image: e.target.files[0],
                  })
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setAddModalOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={handleAddCategory}
            positive
            disabled={isLoading}
          />
        </Modal.Actions>
      </Modal>
      {/* Modal to delete Category */}
      <Confirm
        open={deleteModalOpen}
        header="Delete this category"
        content={
          <div style={{ padding: "25px" }}>
            Are you sure you want to delete this category{" "}
            <b>{selectedCategory?.title}</b>?
          </div>
        }
        onCancel={handleCancelDelete}
        onConfirm={handleDeleteCategory}
      />

      {/* Modal to edit category */}
      <Modal
        size="tiny"
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeIcon
      >
        <Modal.Header>Edit Category</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input
                value={selectedCategory ? selectedCategory?.title : ""}
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    title: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Input
                placeholder="Product description"
                value={selectedCategory ? selectedCategory?.description : ""}
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    description: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Image</label>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setSelectedCategory({
                    ...selectedCategory,
                    image: e.target.files[0],
                  })
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={handleEditCategory}
            positive
          />
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default CategoriesPage;
