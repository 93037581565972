import ProfileCard from "components/common/ProfileCard";
import Main from "layouts/main";
import { Container, Header, Segment } from "semantic-ui-react";

const MyOrders = () => {
  return (
    <>
      <Header> My Recent Orders </Header>
      <Segment>Display Cart Products</Segment>
    </>
  );
};

const MyProfile = () => {
  return (
    <Main>
      <Container style={{ marginTop: "15px", marginBottom: "50px" }}>
        <ProfileCard />
      </Container>
    </Main>
  );
};

export default MyProfile;
