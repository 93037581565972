import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'account',
  initialState: { loading: false },
  reducers: {
    
    // USERPROFILE REDUCER FUNCTION

    // USERPROFILE FETCH LIST
    fetchAccountUserprofileListStart: stateLoadingStart,
    fetchAccountUserprofileListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_list`),
    fetchAccountUserprofileListFailure: stateError,

    // USERPROFILE FETCH ITEM
    fetchAccountUserprofileItemStart: stateLoadingStart,
    fetchAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    fetchAccountUserprofileItemFailure: stateError,

    // USERPROFILE CREATE ITEM
    createAccountUserprofileItemStart: stateLoadingStart,
    createAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    createAccountUserprofileItemFailure: stateError,

    // USERPROFILE UPDATE ITEM
    updateAccountUserprofileItemStart: stateLoadingStart,
    updateAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    updateAccountUserprofileItemFailure: stateError,

    // USERPROFILE DELETE ITEM
    deleteAccountUserprofileItemStart: stateLoadingStart,
    deleteAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_userprofile`),
    deleteAccountUserprofileItemFailure: stateError,


  },
});

export const {
  fetchAccountUserprofileListStart,
  fetchAccountUserprofileListSuccess,
  fetchAccountUserprofileListFailure,
  fetchAccountUserprofileItemStart,
  fetchAccountUserprofileItemSuccess,
  fetchAccountUserprofileItemFailure,
  createAccountUserprofileItemStart,
  createAccountUserprofileItemSuccess,
  createAccountUserprofileItemFailure,
  updateAccountUserprofileItemStart,
  updateAccountUserprofileItemSuccess,
  updateAccountUserprofileItemFailure,
  deleteAccountUserprofileItemStart,
  deleteAccountUserprofileItemSuccess,
  deleteAccountUserprofileItemFailure
} = slice.actions;

export default slice.reducer;
