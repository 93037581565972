import ProductDetailComponent from "components/dashboard/productDetail";
import Main from "layouts/main";
import { Container } from "semantic-ui-react";

const ProductDetails = () => {
  return (
    <Main>
      <Container>
        <br />
        <br />
        <ProductDetailComponent />
        <br />
        <br />
      </Container>
    </Main>
  );
};

export default ProductDetails;
