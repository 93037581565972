import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import {
  Button,
  Form,
  Image,
  Grid,
  Icon,
  Loader,
  Input,
} from "semantic-ui-react";
import { toast } from "react-toastify";

function RandomTransformText({ captchaVerify, setCaptchaVerify }) {
  const [transforms, setTransforms] = useState([]);
  const [settedImage, setSettedImage] = useState();
  const [captchCharacter, setCaptchaCharacter] = useState();

  const [captchaInput, setCaptchaInput] = useState("");
  const [reloadCaptcha, setReloadCaptcha] = useState(false);
  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 5; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaCharacter(randomString);
  };
  const divRef = useRef(null);

  const handleCaptcha = () => {
    if (captchaInput === captchCharacter) {
      toast.success("Captcha verified!!!");
      setCaptchaVerify(true);
    } else {
      toast.error("Enter the correct Captcha!!!");
      setCaptchaVerify(false);
    }
  };

  useEffect(() => {
    const getRandomTransform = () => {
      const rotation = Math.floor(Math.random() * 45) - 10; // Random rotation between -10deg and 10deg
      const skewX = Math.floor(Math.random() * 30) - 10; // Random skewX between -10deg and 10deg
      const skewY = Math.floor(Math.random() * 30) - 10; // Random skewY between -10deg and 10deg
      const scale = Math.random() * 0.5 + 0.75; // Random scale between 0.75 and 1.25

      return `rotate(${rotation}deg) skewX(${skewX}deg) skewY(${skewY}deg) scale(${scale})`;
    };

    const charTransforms =
      captchCharacter &&
      Array.from(captchCharacter).map(() => getRandomTransform());
    setTransforms(charTransforms);
  }, [captchCharacter]);

  const setImage = async () => {
    const canvas = await html2canvas(divRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    setSettedImage(image);
  };

  useEffect(() => {
    setImage();
  }, [transforms]);

  const handleCaptchaInput = (e) => {
    setCaptchaInput(e.target.value);
  };

  useEffect(() => {
    generateRandomString();
    if (reloadCaptcha) {
      setCaptchaVerify(false);
    }
  }, [reloadCaptcha]);

  return (
    <div>
      {!settedImage && <Loader active inline />}
      <img src={settedImage} />
      <Button
        basic
        onClick={() => setReloadCaptcha(!reloadCaptcha)}
        style={{ marginLeft: "15px" }}
        icon="redo"
      />
      <div style={{ opacity: 0 }}>
        <span
          className="captcha"
          ref={divRef}
          style={{
            position: "absolute",
            border: "2px solid #333",
            width: "fit-width",
            padding: 15,
            borderRadius: 10,
            backgroundColor: "white",
          }}
        >
          {transforms &&
            captchCharacter &&
            Array.from(captchCharacter).map((char, index) => (
              <span
                key={index}
                className="char"
                style={{
                  display: "inline-block",
                  transform: transforms[index],
                  fontSize: 24,
                  border:
                    Math.floor(Math.random() * 10) > 5
                      ? "2px solid #333"
                      : "none",
                  padding: 3,
                }}
              >
                {char}
              </span>
            ))}
        </span>
      </div>
      <Grid columns={2} verticalAlign="middle">
        <Grid.Row>
          <Grid.Column>
            <Form.Input
              type="text"
              placeholder="Enter Captcha"
              value={captchaInput}
              onChange={handleCaptchaInput}
            />
          </Grid.Column>
          <Grid.Column>
            <Button primary onClick={handleCaptcha}>
              {" "}
              Verify{" "}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default RandomTransformText;
