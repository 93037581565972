import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'chat',
  initialState: { loading: false },
  reducers: {
    
    // ROOM REDUCER FUNCTION

    // ROOM FETCH LIST
    fetchChatRoomListStart: stateLoadingStart,
    fetchChatRoomListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_list`),
    fetchChatRoomListFailure: stateError,

    // ROOM FETCH ITEM
    fetchChatRoomItemStart: stateLoadingStart,
    fetchChatRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    fetchChatRoomItemFailure: stateError,

    // ROOM CREATE ITEM
    createChatRoomItemStart: stateLoadingStart,
    createChatRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    createChatRoomItemFailure: stateError,

    // ROOM UPDATE ITEM
    updateChatRoomItemStart: stateLoadingStart,
    updateChatRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    updateChatRoomItemFailure: stateError,

    // ROOM DELETE ITEM
    deleteChatRoomItemStart: stateLoadingStart,
    deleteChatRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_room`),
    deleteChatRoomItemFailure: stateError,


    // MESSAGE REDUCER FUNCTION

    // MESSAGE FETCH LIST
    fetchChatMessageListStart: stateLoadingStart,
    fetchChatMessageListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_list`),
    fetchChatMessageListFailure: stateError,

    // MESSAGE FETCH ITEM
    fetchChatMessageItemStart: stateLoadingStart,
    fetchChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    fetchChatMessageItemFailure: stateError,

    // MESSAGE CREATE ITEM
    createChatMessageItemStart: stateLoadingStart,
    createChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    createChatMessageItemFailure: stateError,

    // MESSAGE UPDATE ITEM
    updateChatMessageItemStart: stateLoadingStart,
    updateChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `message_item`),
    updateChatMessageItemFailure: stateError,

    // MESSAGE DELETE ITEM
    deleteChatMessageItemStart: stateLoadingStart,
    deleteChatMessageItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_message`),
    deleteChatMessageItemFailure: stateError,


  },
});

export const {
  fetchChatRoomListStart, fetchChatMessageListStart,
  fetchChatRoomListSuccess, fetchChatMessageListSuccess,
  fetchChatRoomListFailure, fetchChatMessageListFailure,
  fetchChatRoomItemStart, fetchChatMessageItemStart,
  fetchChatRoomItemSuccess, fetchChatMessageItemSuccess,
  fetchChatRoomItemFailure, fetchChatMessageItemFailure,
  createChatRoomItemStart, createChatMessageItemStart,
  createChatRoomItemSuccess, createChatMessageItemSuccess,
  createChatRoomItemFailure, createChatMessageItemFailure,
  updateChatRoomItemStart, updateChatMessageItemStart,
  updateChatRoomItemSuccess, updateChatMessageItemSuccess,
  updateChatRoomItemFailure, updateChatMessageItemFailure,
  deleteChatRoomItemStart, deleteChatMessageItemStart,
  deleteChatRoomItemSuccess, deleteChatMessageItemSuccess,
  deleteChatRoomItemFailure, deleteChatMessageItemFailure
} = slice.actions;

export default slice.reducer;
