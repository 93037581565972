import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Grid, Header, Card, Icon, Segment } from 'semantic-ui-react';

const dashboardItems = [
  { id: 1, icon: 'box', color: 'blue', header: 'Products', link: '/admin/dashboard/products'},
  // { id: 2, icon: 'building', color: 'green', header: 'Suppliers', link: '/admin/dashboard/suppliers'},
  { id: 3, icon: 'sitemap', color: 'teal', header: 'Categories', link: '/admin/dashboard/categories'},
  { id: 4, icon: 'cart', color: 'orange', header: 'Orders', link: '/admin/dashboard/orders'},
  { id: 5, icon: 'payment', color: 'yellow', header: 'Payments', link: '/admin/dashboard/payments'},
  // { id: 6, icon: 'file alternate', color: 'purple', header: 'Invoices', link: '/admin/dashboard/invoices'},
  { id: 7, icon: 'bell', color: 'red', header: 'Notifications', link: '/admin/dashboard/notifications'},
  // { id: 8, icon: 'money bill alternate outline', color: 'brown', header: 'Bills', link: '/admin/dashboard/bills'},
  // { id: 9, icon: 'globe', color: 'olive', header: 'Countries & Regions', link: '/admin/dashboard/countries-and-regions'},
];

const Dashboard = () => {
  return (
    <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
      <Header as='h2' textAlign='center'>Dashboard</Header>
      <Grid columns={3} stackable>
        {dashboardItems.map(item => (
          <Grid.Column key={item.id} as={Link} to={item.link}>
            <Card fluid>
              <Card.Content>
                <Icon name={item.icon} size='huge' color={item.color} />
                <Card.Header style={{marginTop:10}}>{item.header}</Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
