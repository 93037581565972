import axios from "../utils/axios";

import {
  fetchProductCategoryListStart,
  fetchProductCategoryListSuccess,
  fetchProductCategoryListFailure,
} from "../features/product";
import {
  fetchProductCategoryItemStart,
  fetchProductCategoryItemSuccess,
  fetchProductCategoryItemFailure,
} from "../features/product";
import {
  createProductCategoryItemStart,
  createProductCategoryItemSuccess,
  createProductCategoryItemFailure,
} from "../features/product";
import {
  updateProductCategoryItemStart,
  updateProductCategoryItemSuccess,
  updateProductCategoryItemFailure,
} from "../features/product";
import {
  deleteProductCategoryItemStart,
  deleteProductCategoryItemSuccess,
  deleteProductCategoryItemFailure,
} from "../features/product";

import {
  fetchProductProductListStart,
  fetchProductProductListSuccess,
  fetchProductProductListFailure,
} from "../features/product";
import {
  fetchProductProductItemStart,
  fetchProductProductItemSuccess,
  fetchProductProductItemFailure,
} from "../features/product";
import {
  createProductProductItemStart,
  createProductProductItemSuccess,
  createProductProductItemFailure,
} from "../features/product";
import {
  updateProductProductItemStart,
  updateProductProductItemSuccess,
  updateProductProductItemFailure,
} from "../features/product";
import {
  deleteProductProductItemStart,
  deleteProductProductItemSuccess,
  deleteProductProductItemFailure,
} from "../features/product";

// CATEGORY ACTIONS
export const fetchProductCategoryList = async (dispatch, info, key) => {
  try {
    dispatch(fetchProductCategoryListStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).get(
      `/product/category/`,
      { params: info.params, headers: info.headers }
    );
    dispatch(fetchProductCategoryListSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(fetchProductCategoryListFailure({ key, error: error.message }));
  }
};
export const fetchProductCategoryItem = async (dispatch, info, key) => {
  try {
    dispatch(fetchProductCategoryItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).get(
      `/product/category/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      { params: info.params, headers: info.headers }
    );

    dispatch(fetchProductCategoryItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(fetchProductCategoryItemFailure({ key, error: error.message }));
  }
};
export const createProductCategoryItem = async (dispatch, info, key) => {
  try {
    dispatch(createProductCategoryItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).post(
      `/product/category/`,
      info.payload,
      { headers: info.headers }
    );
    dispatch(createProductCategoryItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(createProductCategoryItemFailure({ key, error: error.message }));
  }
};
export const updateProductCategoryItem = async (dispatch, info, key) => {
  try {
    dispatch(updateProductCategoryItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).patch(
      `/product/category/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      info.payload,
      { headers: info.headers }
    );
    dispatch(updateProductCategoryItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(updateProductCategoryItemFailure({ key, error: error.message }));
  }
};
export const deleteProductCategoryItem = async (dispatch, info, key) => {
  try {
    dispatch(deleteProductCategoryItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).delete(
      `/product/category/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      { headers: info.headers }
    );
    dispatch(deleteProductCategoryItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(deleteProductCategoryItemFailure({ key, error: error.message }));
  }
};

// PRODUCT ACTIONS
export const fetchProductProductList = async (dispatch, info, key) => {
  try {
    dispatch(fetchProductProductListStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).get(
      `/product/product/`,
      { params: info.params, headers: info.headers }
    );
    dispatch(fetchProductProductListSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(fetchProductProductListFailure({ key, error: error.message }));
  }
};
export const fetchProductProductItem = async (dispatch, info, key) => {
  try {
    dispatch(fetchProductProductItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).get(
      `/product/product/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      { params: info.params, headers: info.headers }
    );

    dispatch(fetchProductProductItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(fetchProductProductItemFailure({ key, error: error.message }));
  }
};
export const createProductProductItem = async (dispatch, info, key) => {
  try {
    dispatch(createProductProductItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).post(
      `/product/product/`,
      info.payload,
      { headers: info.headers }
    );
    dispatch(createProductProductItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(createProductProductItemFailure({ key, error: error.message }));
  }
};
export const updateProductProductItem = async (dispatch, info, key) => {
  try {
    dispatch(updateProductProductItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).patch(
      `/product/product/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      info.payload,
      { headers: info.headers }
    );
    dispatch(updateProductProductItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(updateProductProductItemFailure({ key, error: error.message }));
  }
};
export const deleteProductProductItem = async (dispatch, info, key) => {
  try {
    dispatch(deleteProductProductItemStart({ key }));
    const response = await axios(localStorage.getItem("FABU")).delete(
      `/product/product/${
        info.params.lookup_field
          ? info.params[info.params.lookup_field]
          : info.params.id || info.params.slug
      }/`,
      { headers: info.headers }
    );
    dispatch(deleteProductProductItemSuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(deleteProductProductItemFailure({ key, error: error.message }));
  }
};
