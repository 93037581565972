import { Button, Modal } from "semantic-ui-react";

const CommonModal = ({
  title,
  children,
  open,
  handleClose,
  button_name,
  button_action,
}) => {
  return (
    <Modal open={open} onClose={handleClose} dimmer="inverted">
      <Modal.Header> {title} </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>
          {" "}
          Cancel{" "}
        </Button>
        <Button positive onClick={button_action}>
          {" "}
          {button_name}{" "}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default CommonModal;
