export const getCartItems = () => {
  const cart_items = localStorage.getItem("cart") || "[]";
  return JSON.parse(cart_items);
};

export const getCartItemCount = () => {
  const cart_items = getCartItems();
  return cart_items.length;
};

export const updateCartItems = (cartItems, setCartItems, navigate) => {
  localStorage.setItem("cart", JSON.stringify(cartItems));
  setCartItems([...cartItems]);
  navigate(`./#${getCartItemCount()}`);
};

export const clearCartItems = () => {
  localStorage.removeItem("cart");
  // navigate(`./#${getCartItemCount()}`);
};
