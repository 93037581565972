import SushiKitchenBackgroundDark from '../../assets/hero/SushiThemeDark.jpg'
import SushiKitchenBackgroundLight from '../../assets/hero/SushiThemeLight.jpg'
import AuthBackground from '../../assets/hero/AuthBackground.jpg'

const BGImage = ({children, theme}) => {
  let image = SushiKitchenBackgroundDark
  let paddingTop = '10vh'
  let paddingBottom = '10vh'
  if (theme === 'light') {
    paddingTop = '1vh'
    paddingBottom = '1vh'
    image = SushiKitchenBackgroundLight
  } else if (theme === 'auth') {
    paddingTop = '10vh'
    paddingBottom = '10vh'
    image = AuthBackground
  }
  return(
    <div style={{
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
      <div style={{
        background: 'linear-gradient(to bottom right, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%)',
        paddingTop,
        paddingBottom,
      }}>
       {children}
      </div>
    </div>
  )
}

export default BGImage
