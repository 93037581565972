import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from 'layouts/dashboard'
import Main from 'components/dashboard'
import AdminLogin from 'components/login/admin'

function Home() {
  return (
    <Dashboard>
      <Main/>
    </Dashboard>
  );
}

export default Home;
