import axios from '../utils/axios';

import {fetchChatRoomListStart, fetchChatRoomListSuccess, fetchChatRoomListFailure} from '../features/chat'
import {fetchChatRoomItemStart, fetchChatRoomItemSuccess, fetchChatRoomItemFailure} from '../features/chat'
import {createChatRoomItemStart, createChatRoomItemSuccess, createChatRoomItemFailure} from '../features/chat'
import {updateChatRoomItemStart, updateChatRoomItemSuccess, updateChatRoomItemFailure} from '../features/chat'
import {deleteChatRoomItemStart, deleteChatRoomItemSuccess, deleteChatRoomItemFailure} from '../features/chat'

import {fetchChatMessageListStart, fetchChatMessageListSuccess, fetchChatMessageListFailure} from '../features/chat'
import {fetchChatMessageItemStart, fetchChatMessageItemSuccess, fetchChatMessageItemFailure} from '../features/chat'
import {createChatMessageItemStart, createChatMessageItemSuccess, createChatMessageItemFailure} from '../features/chat'
import {updateChatMessageItemStart, updateChatMessageItemSuccess, updateChatMessageItemFailure} from '../features/chat'
import {deleteChatMessageItemStart, deleteChatMessageItemSuccess, deleteChatMessageItemFailure} from '../features/chat'


// ROOM ACTIONS
export const fetchChatRoomList = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatRoomListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/room/`, { params: info.params, headers: info.headers });
        dispatch(fetchChatRoomListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatRoomListFailure({key, error: error.message}));
    }
}
export const fetchChatRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchChatRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatRoomItemFailure({key, error: error.message}));
    }
}
export const createChatRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(createChatRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/chat/room/`, info.payload, { headers: info.headers });
        dispatch(createChatRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createChatRoomItemFailure({key, error: error.message}));
    }
}
export const updateChatRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(updateChatRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/chat/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateChatRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateChatRoomItemFailure({key, error: error.message}));
    }
}
export const deleteChatRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteChatRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/chat/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteChatRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteChatRoomItemFailure({key, error: error.message}));
    }
}


// MESSAGE ACTIONS
export const fetchChatMessageList = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatMessageListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/message/`, { params: info.params, headers: info.headers });
        dispatch(fetchChatMessageListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatMessageListFailure({key, error: error.message}));
    }
}
export const fetchChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchChatMessageItemFailure({key, error: error.message}));
    }
}
export const createChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(createChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/chat/message/`, info.payload, { headers: info.headers });
        dispatch(createChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createChatMessageItemFailure({key, error: error.message}));
    }
}
export const updateChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(updateChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateChatMessageItemFailure({key, error: error.message}));
    }
}
export const deleteChatMessageItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteChatMessageItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/chat/message/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteChatMessageItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteChatMessageItemFailure({key, error: error.message}));
    }
}




