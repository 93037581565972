import React from 'react';
import { Link } from 'react-router-dom';
import Main from 'layouts/main'
import AdminLogin from 'components/login/admin'

function Home() {
  return (
    <Main>
      <AdminLogin/>
    </Main>
  );
}

export default Home;
