import Nav from '../components/nav'
import Footer from '../components/footer'

const Main = ({children, heroElement}) => {
  return(
    <>
      <Nav/>
        {heroElement}
        {children}
      <Footer/>
    </>
  )
}

export default Main
