const PAGE_LIMIT = 8
const BIG_LIMIT = 1000

export const configureNotificationNotification = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    notification_fetch_list: state['notification']?.notification_list,
    notification_fetch_item: state['notification']?.notification_item,
    notification_create_item: state['notification']?.notification_item,
    notification_update_item: state['notification']?.notification_item,
    notification_delete_item: state['notification']?.deleted_notification,
  },
  action: {
    fetchNotificationList: () => {
      const notification_fetch_list_request = { dispatch, request: ['notification', 'notification', 'list'], request_info: {}, auth: false}
      utils.action(notification_fetch_list_request)
    },
    fetchNotificationItem: (id) => {
      const notification_fetch_item_request = { dispatch, request: ['notification', 'notification', 'read'], request_info: {id}, auth: false}
      utils.action(notification_fetch_item_request)
    },
    createNotificationItem: (payload) => {
      const notification_create_item_request = { dispatch, request: ['notification', 'notification', 'create'], request_info: payload, auth: false}
      utils.action(notification_create_item_request)
    },
    updateNotificationItem: (id, payload) => {
      const notification_update_item_request = { dispatch, request: ['notification', 'notification', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(notification_update_item_request)
    },
    deleteNotificationItem: (id) => {
      const notification_delete_item_request = { dispatch, request: ['notification', 'notification', 'delete'], request_info: {id}, auth: false}
      utils.action(notification_delete_item_request)
    },
  }
})

export const configureNotificationNotificationtype = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    notificationtype_fetch_list: state['notification']?.notificationtype_list,
    notificationtype_fetch_item: state['notification']?.notificationtype_item,
    notificationtype_create_item: state['notification']?.notificationtype_item,
    notificationtype_update_item: state['notification']?.notificationtype_item,
    notificationtype_delete_item: state['notification']?.deleted_notificationtype,
  },
  action: {
    fetchNotificationtypeList: () => {
      const notificationtype_fetch_list_request = { dispatch, request: ['notification', 'notificationtype', 'list'], request_info: {}, auth: false}
      utils.action(notificationtype_fetch_list_request)
    },
    fetchNotificationtypeItem: (id) => {
      const notificationtype_fetch_item_request = { dispatch, request: ['notification', 'notificationtype', 'read'], request_info: {id}, auth: false}
      utils.action(notificationtype_fetch_item_request)
    },
    createNotificationtypeItem: (payload) => {
      const notificationtype_create_item_request = { dispatch, request: ['notification', 'notificationtype', 'create'], request_info: payload, auth: false}
      utils.action(notificationtype_create_item_request)
    },
    updateNotificationtypeItem: (id, payload) => {
      const notificationtype_update_item_request = { dispatch, request: ['notification', 'notificationtype', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(notificationtype_update_item_request)
    },
    deleteNotificationtypeItem: (id) => {
      const notificationtype_delete_item_request = { dispatch, request: ['notification', 'notificationtype', 'delete'], request_info: {id}, auth: false}
      utils.action(notificationtype_delete_item_request)
    },
  }
})

export const configureNotificationUsernotificationsettings = (utils, dispatch, state) => ({
  initialize: () => {
  },
  data: {
    usernotificationsettings_fetch_list: state['notification']?.usernotificationsettings_list,
    usernotificationsettings_fetch_item: state['notification']?.usernotificationsettings_item,
    usernotificationsettings_create_item: state['notification']?.usernotificationsettings_item,
    usernotificationsettings_update_item: state['notification']?.usernotificationsettings_item,
    usernotificationsettings_delete_item: state['notification']?.deleted_usernotificationsettings,
  },
  action: {
    fetchUsernotificationsettingsList: () => {
      const usernotificationsettings_fetch_list_request = { dispatch, request: ['notification', 'usernotificationsettings', 'list'], request_info: {}, auth: false}
      utils.action(usernotificationsettings_fetch_list_request)
    },
    fetchUsernotificationsettingsItem: (id) => {
      const usernotificationsettings_fetch_item_request = { dispatch, request: ['notification', 'usernotificationsettings', 'read'], request_info: {id}, auth: false}
      utils.action(usernotificationsettings_fetch_item_request)
    },
    createUsernotificationsettingsItem: (payload) => {
      const usernotificationsettings_create_item_request = { dispatch, request: ['notification', 'usernotificationsettings', 'create'], request_info: payload, auth: false}
      utils.action(usernotificationsettings_create_item_request)
    },
    updateUsernotificationsettingsItem: (id, payload) => {
      const usernotificationsettings_update_item_request = { dispatch, request: ['notification', 'usernotificationsettings', 'update'], request_info: {id, ...payload}, auth: false}
      utils.action(usernotificationsettings_update_item_request)
    },
    deleteUsernotificationsettingsItem: (id) => {
      const usernotificationsettings_delete_item_request = { dispatch, request: ['notification', 'usernotificationsettings', 'delete'], request_info: {id}, auth: false}
      utils.action(usernotificationsettings_delete_item_request)
    },
  }
})

