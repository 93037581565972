import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Orders from "components/dashboard/orders";
import AdminLogin from "components/login/admin";

function Home() {
  return (
    <Dashboard activeItem={"orders"}>
      <Orders />
    </Dashboard>
  );
}

export default Home;
