import React, { useState, useEffect } from "react";
import {
  List,
  Segment,
  Header,
  Icon,
  Container,
  Card,
  Message,
} from "semantic-ui-react";

import { configureNotificationNotification } from "services/access/notification";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureNotificationNotification(
    utils,
    dispatch,
    state
  );
  useEffect(() => {
    action.fetchNotificationList();
  }, []);
  useEffect(() => {
    if (data.notification_fetch_list) {
      setNotifications(
        data.notification_fetch_list.map((i) => ({
          id: i.id,
          type: i.type,
          title: i.title,
          message: i.message,
          link: i.link,
        }))
      );
    }
  }, [data.notification_fetch_list]);

  return (
    <Container>
      <Header as="h3">Notifications</Header>

      <Segment>
        <List divided size="large">
          {notifications?.length === 0 && (
            <Message info> No notifications are available. </Message>
          )}
          {notifications.map((notification) => (
            <List.Item key={notification.id}>
              <List.Icon
                name={
                  notification.type === "info"
                    ? "info circle"
                    : notification.type === "warning"
                    ? "warning circle"
                    : "check circle outline"
                }
                color={
                  notification.type === "info"
                    ? "blue"
                    : notification.type === "warning"
                    ? "yellow"
                    : "green"
                }
              />
              <List.Content>
                <List.Header>{notification.title}</List.Header>
                <List.Description>{notification.message}</List.Description>
                {notification.link && (
                  <List.Item>
                    <a href={notification.link}>View Details</a>
                  </List.Item>
                )}
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>
    </Container>
  );
};

export default Notification;
