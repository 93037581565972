import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Header } from 'semantic-ui-react';

import { configureOrderOrder } from "services/access/order";
import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboardInvoices = () => {
  const [invoices, setInvoices] = useState([]); // State to store the list of invoices
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal for adding/editing invoices

  // Function to fetch invoices from the backend API (dummy implementation)
  const fetchInvoices = () => {
    // Dummy API call to fetch invoices
    // Replace this with actual API call using useEffect hook
    const dummyInvoices = [
      { id: 1, customerName: 'John Doe', totalAmount: 100.00, status: 'Pending' },
      { id: 2, customerName: 'Jane Smith', totalAmount: 150.00, status: 'Paid' },
      { id: 3, customerName: 'Alice Johnson', totalAmount: 75.00, status: 'Paid' }
      // Add more dummy invoices as needed
    ];
    setInvoices(dummyInvoices);
  };

  // Function to handle the click event for adding/editing invoices
  const handleAddEditInvoice = () => {
    // Logic to open the modal for adding/editing invoices
    setShowModal(true);
  };

  // Use useEffect hook to fetch invoices when the component mounts
  useEffect(() => {
    fetchInvoices();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <Container>
      <Header as='h1'>Invoices</Header>
      <Button primary onClick={handleAddEditInvoice}>Add Invoice</Button>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Total Amount</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoices.map(invoice => (
            <Table.Row key={invoice.id}>
              <Table.Cell>{invoice.id}</Table.Cell>
              <Table.Cell>{invoice.customerName}</Table.Cell>
              <Table.Cell>{invoice.totalAmount}</Table.Cell>
              <Table.Cell>{invoice.status}</Table.Cell>
              <Table.Cell>
                <Button color='blue' onClick={handleAddEditInvoice}>Edit</Button>
                <Button color='red' onClick={() => console.log('Delete invoice:', invoice.id)}>Delete</Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {/* Modal for adding/editing invoices */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>Add/Edit Invoice</Modal.Header>
        <Modal.Content>
          {/* Form for adding/editing invoices */}
          <div>Add/Edit Invoice Form</div>
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default AdminDashboardInvoices;
