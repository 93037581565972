import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Settings from "components/dashboard/settings";
import AdminLogin from "components/login/admin";

function Home() {
  return (
    <Dashboard activeItem={"settings"}>
      <Settings />
    </Dashboard>
  );
}

export default Home;
