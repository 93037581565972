import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Payment from "components/dashboard/payments";
import AdminLogin from "components/login/admin";

function Home() {
  return (
    <Dashboard activeItem={"payments"}>
      <Payment />
    </Dashboard>
  );
}

export default Home;
